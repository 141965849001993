<template>
  <div id="app" >
    <div>
      <el-cascader
       style="width:300px;margin: 10px 10px 5px 0;"
        ref="cascader"
        placeholder="点击筛选"
        :options="options"
        :props="{checkStrictly: true,value: 'id',label: 'name'}"
        clearable
        collapse-tags
        filterable
         @change="handleChange"
        >
      </el-cascader>
      <el-input placeholder="输入设备型号进行搜索..." v-model="query.deviceModel" prefix-icon="el-icon-search"  style="margin: 10px 10px 5px 0; width: 200px">
      </el-input>
      <el-button type="primary"  @click="queryList">查询</el-button>
      <el-button type="primary"  @click="restList">重置</el-button>
      <el-button type="primary" @click="deviceInDialog = true">设备入库</el-button>
      <el-button type="primary" @click="deviceOutDialog = true">设备出库</el-button>
    </div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-submenu index="0">
        <template slot="title">仓库选择</template>
        <el-menu-item v-for="item in pickDepotList" :index="item.id" :key="item.id" >{{item.depotName}}</el-menu-item>
      </el-submenu>
    </el-menu>
    <div>
      <el-table
        :data="tableData" 
          style="width: 100%;margin-bottom: 20px;"
          row-key="id"
          border
         >
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="depotName" label="仓库" width="180"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
        <el-table-column prop="deviceModel" label="设备型号"> </el-table-column>
        <el-table-column prop="factoryName" label="设备厂家"> </el-table-column>
        <el-table-column prop="sortName" label="设备分类"> </el-table-column>

        <el-table-column prop="in" label="库存预警">
            <template slot-scope="scope">
              <el-tag size="medium" :type="scope.row.warnType" 
                >{{scope.row.warnMessage}}</el-tag
              >
            </template>
        </el-table-column>
        <el-table-column prop="deviceIn" label="总入库"> </el-table-column>
        <el-table-column prop="deviceOut" label="总出库"> </el-table-column>
        <el-table-column prop="deviceCount" label="总剩余"> </el-table-column>
        <el-table-column prop="unit" label="计量单位"> </el-table-column>
        <el-table-column prop="warn" label="报警数量"> </el-table-column>
      </el-table>
    </div>
    <el-pagination
        :current-page.sync="pages.current"
        :page-size="pages.limit"
        background
        :total="pages.total"
        layout="total, prev, pager, next, jumper"
        style=" position: fixed; bottom:20px;padding-top: 10px;  text-align: center"
        @current-change="currentPage"
      ></el-pagination>
    <div class="dialog">
      <el-dialog
        title="设备入库"
        :visible.sync="deviceInDialog"
        width="45%"
        :close-on-click-modal="false"
        @close="aaa"
        >
        <div>
          <device-in ref="indevice" @getDevice="getDevice" @dialogClose="dialogClose" :depotList="depotList"></device-in>
        </div>
      </el-dialog>

      <el-dialog
        title="设备出库"
        :visible.sync="deviceOutDialog"
        width="45%"
        :close-on-click-modal="false"
        @close="bbb"
        >
        <div>
          <device-out ref="outdevice" @getDevice="getDevice" @dialogClose="dialogClose" :depotList="depotList"></device-out>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import DeviceCount from '@/api/mangeDevice/DeviceCount'
import Devicefactory from '@/api/mangeDevice/DeviceFactory'

import DeviceIn from './DeviceIn.vue';
import DeviceOut from './DeviceOut.vue';
import DeviceDepot from "@/api/mangeDevice/DeviceDepot"

export default {
  components: { DeviceIn, DeviceOut },

  data() {
    return {
      query:{
        factory:"",
        device:"",
        deviceModel:""
      },
      warn:{
        warnType:"",
        warnMessage:""
      },
      activeIndex: '', // 仓库的id
      deviceInDialog:false,
      deviceOutDialog:false,
      pages:{
        total: 0,
        limit: 10,
        current: 1,
      },
      tableData: [],
      depotList:[], // 传给入库出库组件的
      pickDepotList:[], // 传给入库出库组件的
      options:[], // 筛选树

      searchType:0,  // 0 搜全部 1 搜厂家 2 搜设备 3 搜型号
    };
  },
  async created(){
    await this.queryDepotList()
    await this.getDevice()
    await this.getfactoryTree()
  },
  methods: {
   
    aaa(){
      this.$refs.indevice.restForm();
    },
    bbb(){
      this.$refs.outdevice.restForm();
    },
    async handleChange(val){
      // this.query.deviceModel = ""
      const id = val[val.length -1]
      let value;
      if (val.length == 1) {
        value = "factory";
      }
      if(val.length == 2) {
        value = "device";
      }
      if(val.length == 3) {
        value = "model";
      }
      let query = "queryBy" + value
      return await this[query](id)
			
		},
    async queryByfactory(e){
      this.searchType = 1
      let form = {depotId:this.activeIndex, factoryId:e,...this.pages}
      const res =  await DeviceCount.getDeviceListByFactory(form)
      this.handleResList(res)
      this.query.deviceModel = ""
    },
    async queryBydevice(e){
      this.searchType = 2
      let form = {depotId:this.activeIndex, deviceId:e,...this.pages}
      const res =  await DeviceCount.getDeviceListByDevice(form)
      this.handleResList(res)
      this.query.deviceModel = ""
    },
    async queryBymodel(e){
      this.searchType = 3
        let form = {depotId:this.activeIndex,detailId:e,...this.pages}
        const res = await DeviceCount.getDeviceListByModel(form)
        this.handleResList(res) // 处理返回值
        this.query.deviceModel = ""
    },
    async queryList(){
      let form = {depotId:this.activeIndex, deviceModel:this.query.deviceModel,...this.pages}
      const res =  await DeviceCount.allQueryByModel(form)
      this.handleResList(res)
      this.$refs['cascader'].$refs.panel.clearCheckedNodes();  // 清空筛选

    },
    async handleResList(res){
      if (res.data.data.map) {
        if (!res.data.data.map.list) {
          this.$message.warning("暂无数据")
          this.searchType = 0
          return
        }
        let result = res.data.data.map.list // 全部仓库的数量
        result.map(item => {
          item.warnType = "success"
          item.warnMessage = "库存充足"
          if (item.deviceCount < item.warn) {
            item.warnType = "danger"
            item.warnMessage = "库存不足"
          }
        })
        const arr2 = JSON.parse(JSON.stringify(result))
        this.tableData = arr2
        this.pages.total = res.data.data.map.total
        this.$message.success("查询成功")
        return
      }
      this.searchType = 0
      this.$message.warning("暂无数据")
      return 
    },
    async getfactoryTree(){
      const res = await Devicefactory.factoryTree()
      const result = JSON.parse(JSON.stringify(res.data.data.list))
      this.options = result
    },
    dialogClose()  {
      this.deviceInDialog =false,
      this.deviceOutDialog =false
    },
    async queryDepotList(){
      let a = {}
      const res = await DeviceDepot.queryDepotList(a)
      if (res.data.data.list) {
        this.depotList = res.data.data.list   // 传给组件的
        let newData = JSON.parse(JSON.stringify(this.depotList))  // 自己用的
        this.pickDepotList = newData.map(item => {
          return {
            depotName:item.depotName,
            id:item.id
          }
        })
        this.activeIndex = this.pickDepotList[0].id
      }
    },
    async handleSelect(key, keyPath) { // 切换仓库
      console.log(key);
      this.activeIndex = key
      await this.getDevice()
      this.$refs['cascader'].$refs.panel.clearCheckedNodes(); 
    },
    async restList(){
      await this.getDevice() // 重置表格
      this.$refs['cascader'].$refs.panel.clearCheckedNodes();  // 清空筛选
      this.query.deviceModel = ""
    },
    async currentPage(){
      console.log(this.searchType );
      if (this.searchType == 0) {
        return await this.getDevice()
      }
      if (this.searchType == 1) {
        return await this.queryByfactory()
        
      }
      if (this.searchType == 2) {
        return await this.queryBydevice()
      }
      if (this.searchType == 3) {
        return await this.queryBymodel()
      }
    },
    async getDevice(){
      // 获取表单
      this.searchType = 0
      let form = {depotId:this.activeIndex,...this.pages}
      const res = await DeviceCount.getDeviceList(form)
      console.log(res);
      let result = res.data.data.list // 全部仓库的数量
      result.map(item => {
        item.warnType = "success"
        item.warnMessage = "库存充足"
        if (item.deviceCount < item.warn) {
          item.warnType = "danger"
          item.warnMessage = "库存不足"
        }
      })
      const arr2 = JSON.parse(JSON.stringify(result))
      this.tableData = arr2
      this.pages.total = res.data.data.total
      this.triggerBrotherMethods()
    },
    triggerBrotherMethods() {
     this.$emit( 'clickBrotherBtn')
   },
  },
};
</script>
<style lang="scss" scope>

</style>
