import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDeviceOutbound'

export default {
    // create 查的
    queryList(mangeDeviceOutbound) {
        return request({
            url: `${api_name}/queryList`,
            method: 'post',
            data:mangeDeviceOutbound
        })
    },
    // create 客户查
    queryListByCustomer(mangeDeviceOutbound) {
        return request({
            url: `${api_name}/queryListByCustomer`,
            method: 'post',
            data:mangeDeviceOutbound
        })
    },
    updateOne(mangeDeviceOutbound) {
        return request({
            url: `${api_name}/updateOne`,
            method: 'post',
            data:mangeDeviceOutbound
        })
    },
    exportOutbound() {
        return request({
            url: `${api_name}/exportOutbound`,
            method: 'post',
        })
    },

    queryListByModel(mangeDeviceOutbound) {
        return request({
            url: `${api_name}/queryListByModel`,
            method: 'post',
            data:mangeDeviceOutbound
        })
    },
    updateImg(formData) {
        return request({
          url: `${api_name}/updateImg`,
          method: 'post',
          data: formData
        })
      },
}