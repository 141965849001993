import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDevice'

export default {

    getDevice(mangeDevice) {
        return request({
            url: `${api_name}/getDevice`,
            method: 'post',
            data:mangeDevice
        })
    },
    addDevice(mangeDevice) {
        return request({
            url: `${api_name}/addDevice`,
            method: 'post',
            data:mangeDevice
        })
    },
  

}