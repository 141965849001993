<template>
  <div>
    <el-tabs :tab-position="tabPosition" style="height: auto">
      <el-tab-pane label="产品录入">
        <div style="margin-top:10px">
          <device-entry @clickBorther="bortherMethods"></device-entry>
        </div>
      </el-tab-pane>
      <el-tab-pane label="仓库管理">
        <device-depot ref="depot" @queryDepot="queryDepot"></device-depot>
      </el-tab-pane>
      <el-tab-pane label="分类管理">
        <div style="margin-top:10px">
          <device-sort ref="sort"></device-sort>
        </div>
      </el-tab-pane>
      <el-tab-pane label="库存总计">
        <div style="margin-top:10px">
          <device-detail  ref="detail" @clickBrotherBtn="InHistoryList"></device-detail>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史记录">
        <device-history ref= "borther" :user="user"></device-history>
      </el-tab-pane>
      <!-- <el-tab-pane label="客户管理">
        <demo></demo>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import DeviceDepot from './mangeDevice/DeviceDepot.vue';
import DeviceDetail from './mangeDevice/DeviceDetail.vue';
import DeviceEntry from './mangeDevice/DeviceEntry.vue';
import DeviceHistory from './mangeDevice/DeviceHistory.vue'
import DeviceIn from './mangeDevice/DeviceIn.vue'
import DeviceOut from './mangeDevice/DeviceOut.vue';
import DeviceSort from './mangeDevice/DeviceSort.vue';
import demo from './mangeDevice/demo.vue';



export default {
  components:{
    DeviceDetail,
    DeviceHistory,
    DeviceIn,
    DeviceOut,
    DeviceEntry,
    DeviceSort,
    DeviceDepot,
    demo
  },
  data() {
    return {
      tabPosition: "left",
      user:"mingzi"
    };
  },
  methods: {
    bortherMethods(){
      this.$refs.sort.getSortTree()
    },
    queryDepot(){
      this.$refs.detail.queryDepotList()
    },
    InHistoryList() {
      this.$refs.borther.InHistoryList()
      this.$refs.borther.OutHistoryList()
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-tabs__item {
    padding: 0 20px;
    height: 45px;
    line-height: 60px;
    display: inline-block;
    list-style: none;
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    position: relative;
} 

/deep/ .el-tabs__item.is-active {
    color: #409EFF;
}
/deep/ .el-tabs__content {
    position: relative;
}
</style>
