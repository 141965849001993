import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDeviceStorage'

export default {
    queryList(mangeDeviceStorage) {
        return request({
            url: `${api_name}/queryList`,
            method: 'post',
            data:mangeDeviceStorage
        })
    },

    queryListByModel(mangeDeviceStorage) {
        return request({
            url: `${api_name}/queryListByModel`,
            method: 'post',
            data:mangeDeviceStorage
        })
    },

    exportStorage() {
        return request({
            url: `${api_name}/exportStorage`,
            method: 'post',
        })
    },
}