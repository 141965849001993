<template>
  <div id="app" class="url">

    <!-- 公司结构 -->
    <div>
      <el-input placeholder="输入分类名进行搜索..." prefix-icon="el-icon-search" v-model="filterText" style="margin: 0 10px 5px 0; width: 300px">
      </el-input>
      <el-button type="primary" @click="dialogVisible1 = true" >添加新分类</el-button>

      <el-dialog title="添加新分类" :visible.sync="dialogVisible1" width="30%">
        <el-form ref="form" :model="form" :rules="formRules" label-width="80px">
          <el-form-item label="分类名称" prop="sortName">
            <el-input v-model="form.sortName" placeholder="请输入分类名称"></el-input>
          </el-form-item>
          <el-form-item label="分类别名" prop="sortAlias">
            <el-input v-model="form.sortAlias" placeholder="请输入分类名称"></el-input>
          </el-form-item>
        </el-form>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="addNewDeviceSort('form')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 添加子分类弹框 -->
      <el-dialog title="添加分类" :visible.sync="dialogVisible2" width="30%" :close-on-click-modal="false">
        <div>
          <table>
            <tr style="margin-bottom:10px">
              <td>
                <el-tag style="font-size:16px">上级分类</el-tag>
              </td>
              <td style="font-size:16px;padding-left:5px;font-family:'宋体';font-weight:600">{{ perName }}</td>
            </tr>
          </table>
          <br>
          <el-form ref="company" :model="company" :rules="companyRule" label-width="80px">
             <el-form-item label="分类名称" prop="sortName">
              <el-input v-model="company.sortName"></el-input>
            </el-form-item>
             <el-form-item label="分类别名" prop="sortAlias">
              <el-input v-model="company.sortAlias"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="doAddDeviceSort('company')">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="编辑分类" :visible.sync="dialogVisible3" width="30%">
        <el-form ref="forms" :model="forms" :rules="formsRules" label-width="80px">
          <el-form-item label="分类名称" prop="sortName">
            <el-input v-model="forms.sortName" placeholder="请输入分类名称"></el-input>
          </el-form-item>
          <el-form-item label="分类别名" prop="sortAlias">
            <el-input v-model="forms.sortAlias" placeholder="请输入分类名称"></el-input>
          </el-form-item>
        </el-form>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="doeditDeviceSort('forms')">确 定</el-button>
        </span>
      </el-dialog>

      <el-tree :check-strictly="true" :data="allCompanyList" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" ref="tree" style="height: 700px;overflow-y:scroll">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <div style="display:flex">
                <el-button type="primary" plain size="mini" class="add" @click="() => addDeviceSort(data)">
                  添加
                </el-button>
                <el-button :hidden="sortBtnShow" type="success" plain size="mini" class="add" @click="() => editDeviceSort(data)">
                  编辑
                </el-button>
                <el-button :hidden="sortBtnShow" type="danger" plain size="mini" class="del" @click="() => delDeviceSort(data.id)">
                  删除
                </el-button>
                <el-button :hidden="!sortBtnShow" type="success" plain size="mini" class="add" @click="() => checkDeviceSort(data)">
                  选择
                </el-button>
            </div>
          </span>
        </span>
      </el-tree>
    </div>

  </div>
</template>

<script>
import DeviceSort from "@/api/mangeDevice/DeviceSort"
import { mapState} from 'vuex'

export default {
  props: {
    sortBtnShow: {
        type: Boolean,
        default:false 
    }
  },
  data() {
    return {
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,


      // allCompanyList: [], // 公司集合
      // 添加新分类
      form: {
        id:'',
        sortName: "",
        sortAlias: "",
      },
      // 添加新分类
      forms: {
        id:'',
        sortName: "",
        sortAlias: "",
      },
      formRules:{
        sortName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ],
        sortAlias: [
          { required: true, message: '请输入分类别名', trigger: 'blur' }
        ],
      },
      formsRules:{
        sortName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ],
        sortAlias: [
          { required: true, message: '请输入分类别名', trigger: 'blur' }
        ],
      },
      // 添加子分类
      company: {
        sortName: "",
        sortAlias: "",
        pid: -1,
      },
      companyRule:{
        sortName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ],
        sortAlias: [
          { required: true, message: '请输入分类别名', trigger: 'blur' }
        ],
      },
      perName: "",  // 父分类名称
      filterText: "", // 过滤文本

      defaultProps: { 
        children: "children", // 子分类节点
        label: "sortName", // 分类名
      },

    };
  },

  created() {
    this.getSortTree();
  }, 
   
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapState({'allCompanyList':state=>state.sort.sortList})
  },
  methods: {
    // 查询所有公司结构
    async getSortTree() {
       await this.$store.dispatch("sort/querySortTreeAsync")
       
      //  this.allCompanyList = this.$store.state.sort.sortList;
        console.log(this.allCompanyList);
    }, 

    filterNode(value, data) {
      console.log(value,data);
      if (!value) return true;
      return data.sortName.indexOf(value) !== -1 || data.sortAlias != null ? data.sortAlias.indexOf(value) !== -1:''
    }, 

    // 添加新的顶级分类filterText
    async addNewDeviceSort(form) {
     const valid = await this.$refs[form].validate()
     if (valid) {
      const res = await DeviceSort.addSort(this.form)
       //提示信息
      if (res.data.message == "添加成功") {
        this.$message.success(res.data.message)
      }else {
        this.$message.warning(res.data.message)
      }
      await this.getSortTree()
      this.dialogVisible1 = false

      this.$refs[form].resetFields()
     }else {
        console.log('error submit!!');
        return false;
      }
    },

    // 添加子分类
    addDeviceSort(data) {
      this.perName = data.sortName // 上级分类名  用来展示
      this.company.pid = data.id // 上级id  // 用来传参
      this.dialogVisible2 = true;
    },
    checkDeviceSort(data){
      this.$emit('events',data) // true为需要传递的参数对象
    },
    async doAddDeviceSort(company) {
     const valid = await this.$refs[company].validate()
     if(valid){
      const res = await DeviceSort.addSort(this.company)
      //提示信息
      if (res.data.message == "添加成功") {
        this.$message.success(res.data.message)
      }else {
        this.$message.warning(res.data.message)
      }
      this.getSortTree()
      this.dialogVisible2 = false
      this.$refs[company].resetFields()
     } else {
        console.log('error submit!!');
        return false;
     }
    },
    editDeviceSort(data){
      this.forms = data
      this.dialogVisible3 = true;
    },
    async doeditDeviceSort(forms) {
     const valid = await this.$refs[forms].validate()
     if(valid){
      const res = await DeviceSort.updateSort(this.forms)
      this.$message.success(res.data.message)
      this.getSortTree()
      this.$refs[forms].resetFields()
      this.dialogVisible3 = false
     } else {
        console.log('error submit!!');
        return false;
     }
    },
    // 删除公司
    async delDeviceSort(id) {
        this.form.id = id
        const confirmRes  = await this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch(
          err => console.log(err)
        )
        if (confirmRes !== 'confirm') 
            return this.$message.info('已经取消删除')

        const res = await DeviceSort.delSortById(this.form)
        //提示信息
        if (res.data.message == "删除成功") {
           this.$message.success(res.data.message);
        }else {
           this.$message.warning(res.data.message);
        }
        //回到页面并刷新
        this.getSortTree();
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 10px 5px;
}
// input type=number时 去掉后面上下按钮
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.add,
.del {
  font-size: 16px;
  padding: 2px 5px;
}

/deep/.el-dialog__title {
  font-size: 24px;
  font-weight: 600;
  color: #555;
}

.company-info {
  width: 100%;
  box-sizing: border-box;
  //   border: 1px solid red;
  display: flex;

  // height: 100px;
  .left {
    width: 30%;
    height: 282px;

    // border: 1px solid #aaa;
    .img {
      margin-left: 20%;
      box-sizing: border-box;
      width: 70%;
      height: 282px;
      //   border: 1px solid #aaa;
    }
  }

  .right {
    width: 70%;
    // border: 1px solid rgb(247, 110, 110);
  }
}

.inpt {
  width: 40%;
  //   opacity: 0.2;
  display: none;
}

.upload {
  margin: 10px;
  cursor: pointer;
}

.icon:hover {
  color: aquamarine;
}
</style>