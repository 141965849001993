<template>
  <div style="height:100%">
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="入库历史" name="first">
          <div style="margin:10px 0">
            <el-input placeholder="输入设备型号进行搜索..." prefix-icon="el-icon-search" v-model="searchIn"  style="margin: 0 10px 5px 0; width: 300px">
            </el-input>
            <el-button type="primary" @click="inhistorylistbymodel">查询</el-button>
            <el-button type="primary" @click="restInList()">重置</el-button>
            <!-- 导出按钮 -->
            <el-button @click="export2ExcelIn" type="primary">导出</el-button>
          </div>
          <div style="height:650px; overflow-y: auto;">
            <el-table
                :data="inHistory"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                border>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-table
                    :data="props.row.historyInfo"
                    style="width: 100%;margin-bottom: 5px;"
                    row-key="id"
                    border>
                    <el-table-column type="index" label="" width="50"></el-table-column>
                    <el-table-column prop="storageTime" label="入库时间"></el-table-column>
                    <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
                    <el-table-column prop="deviceName" label="设备名称"></el-table-column>
                    <el-table-column prop="factoryName" label="设备厂家"></el-table-column>
                    <el-table-column prop="sortName" label="设备分类"></el-table-column>
                    <el-table-column prop="depotName" label="入库仓库"></el-table-column>
                    <el-table-column prop="num" label="入库数"></el-table-column>
                    <el-table-column prop="unit" label="计量单位"></el-table-column>
                    <el-table-column prop="actionType" label="入库类型"></el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column type="index" label="序号" width="50"></el-table-column>
              <el-table-column prop="createTime" label="操作日期" > </el-table-column>
              <el-table-column prop="people" label="操作人"> </el-table-column>
              <el-table-column prop="historyInfo[0].depotName" label="入库仓库"> </el-table-column>
              <el-table-column prop="historyInfo[0].actionType" label="入库类型"> </el-table-column>
              <el-table-column prop="remark" label="备注"> </el-table-column>
            </el-table>
          </div>
          <el-pagination
              :current-page.sync="inPage.current"
              :page-size="inPage.limit"
              background
              :total="inPage.total"
              layout="total, prev, pager, next, jumper"
              style=" position: fixed; bottom:20px;padding-top: 10px;  text-align: center"
              @current-change="inCurrentPage"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="出库历史" name="second">
          <div style="margin:10px 0">
            <el-input placeholder="输入设备型号搜索..." prefix-icon="el-icon-search" v-model="queryBy.model"  style="margin: 0 10px 5px 0; width: 200px">
            </el-input>
            <span style="font-size:25px;">/</span>
            <el-input placeholder="输入客户名称搜索..."  v-model="queryBy.customer"  style="margin: 0 10px 5px 10px; width: 200px">
            </el-input>
            <el-input placeholder="输入项目名称搜索..."  v-model="queryBy.outProject"  style="margin: 0 10px 5px 0; width: 200px">
            </el-input>
            <el-button type="primary" @click="clickQueryOut">查询</el-button>

            <el-button type="primary" @click="restOutList()" >重置</el-button>
            <el-button @click="export2ExcelOut" type="primary">导出</el-button>

          </div>
           <div style="height:650px; overflow-y: auto;">
            <el-table
                :data="outHistory"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                border>
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table
                      :data="props.row.historyInfo"
                      style="width: 100%;margin-bottom: 5px;"
                      row-key="id"
                      border>
                      <el-table-column type="index" label="" width="50"></el-table-column>
                      <el-table-column prop="outboundTime" label="出库时间"></el-table-column>
                      <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
                      <el-table-column prop="deviceName" label="设备名称"></el-table-column>
                      <el-table-column prop="factoryName" label="设备厂家"></el-table-column>
                      <el-table-column prop="sortName" label="设备分类"></el-table-column>
                      <el-table-column prop="depotName" label="出库仓库"></el-table-column>
                      <el-table-column prop="num" label="出库数"></el-table-column>
                      <el-table-column prop="unit" label="计量单位"></el-table-column>
                      <el-table-column prop="remark" label="备注"></el-table-column>

                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="createTime" label="操作日期"  width="170"> </el-table-column>
                <el-table-column prop="people" label="操作人"> </el-table-column>
                <el-table-column prop="actionType" label="出库类型"> </el-table-column>
                <el-table-column prop="awbNo" label="运单号"> </el-table-column>
                <el-table-column prop="customer" label="发货客户 "> </el-table-column>
                <el-table-column prop="outIp" label="收货地址"> </el-table-column>
                <el-table-column prop="outPeople" label="收货人"> </el-table-column>
                <el-table-column prop="outPhone" label="收货电话" width="auto"> </el-table-column>
                <el-table-column prop="outProject" label="项目名称"> </el-table-column>
                <el-table-column  label="状态"  align="center">
                  <template slot-scope="scope">
                    <el-tag size="medium" v-if="scope.row.state== 0"
                      >已发货</el-tag
                    >
                    <el-tag
                      size="medium"
                      type="success"
                      v-if="scope.row.state== 1"
                      >已签收</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"> </el-table-column>
                <el-table-column label="操作"  fixed="right" width="400">
                  <template slot-scope="row">
                      <el-button
                        size="medium"
                        type="primary"
                        v-if="row.row.photo"
                        @click="imgFormDialogs(row.row)"
                      >查看回执</el-button>
                      <el-button
                        size="medium"
                        type="primary"
                        v-else
                        @click="imageDialog(row.row)"
                      >上传回执</el-button>
                      <el-button
                        size="medium"
                        type="primary"
                        @click="look(row.row)"
                      >查看</el-button>
                      <el-button
                        size="medium"
                        type="primary"
                        :disabled="row.row.state == 1"
                        @click="editNo(row.row)"
                      >编辑</el-button>
                      <el-button
                        v-if="row.row.state == 0"
                        size="medium"
                        type="primary"
                        @click="checkIt(row.row)"
                      >签收</el-button> 
                      <el-button
                        v-if="row.row.state == 1"
                        size="medium"
                        type="success"
                      >已签收</el-button> 
                  </template>
              </el-table-column>
            </el-table>
          </div>

          <el-pagination
              :current-page.sync="outPage.current"
              :page-size="outPage.limit"
              background
              :total="outPage.total"
              layout="total, prev, pager, next, jumper"
              style=" position: fixed; bottom:20px;padding-top: 10px;  text-align: center"
              @current-change="inCurrentPage"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
      <div>
        <el-dialog
          :close-on-click-modal="false"
          destroy-on-close
          title="上传回执单"
          :visible.sync="imgDialog"
          width="30%"
          @close="imgDialogClose"
          center
          >
          <input type="file" @change="changeFile">
          <button @click="upload()">上传</button>
        </el-dialog>
        <el-dialog
          :close-on-click-modal="false"
          destroy-on-close
          :visible.sync="imgFormDialog"
          width="60%"
          center
          >
          <div>
            <!-- <img style="width: 80px" :src="baseUrl + imgsrc" /> -->
            <el-image :src="baseUrl + imgsrc" ></el-image>
          </div>
        </el-dialog>

        <el-dialog
          title="发货清单"
          :visible.sync="dialogVisible"
          width="80%"
          center
          >
            <el-form :model="formList" label-width="80px" >
              <el-row>
                  <el-col :span="12">
                    <el-form-item label="发货人" >
                      <el-input readonly v-model="formList.people"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="收件人" >
                      <el-input readonly v-model="formList.outPeople"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="12">
                    <el-form-item label="发货地址" >
                      <el-input v-model="formList.id"></el-input>
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="12">
                    <el-form-item label="收货地址" >
                      <el-input readonly v-model="formList.outIp"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="12">
                    <el-form-item label="发货电话" >
                      <el-input v-model="formList.id"></el-input>
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="12">
                    <el-form-item label="收货电话" >
                      <el-input readonly v-model="formList.outPhone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="项目名称" >
                      <el-input readonly v-model="formList.outProject"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="发货日期" >
                      <el-input readonly v-model="formList.createTime"></el-input>
                    </el-form-item>
                  </el-col>
              </el-row>
            </el-form>
            <el-table :data="formTable" border style="width: 100%" show-summary>
              <el-table-column prop="deviceName" label="设备名称"></el-table-column>
              <el-table-column prop="deviceModel" label="型号"></el-table-column>
              <el-table-column prop="unit" label="单位"></el-table-column>
              <el-table-column prop="num" label="数量"></el-table-column>
              <el-table-column prop="remark" label="备注"></el-table-column>
            </el-table>
            <div class="sigdate">
              <el-form>
                <el-col :span="12">
                  <el-form-item label="收货单位及验收人（签字或盖章）：">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="日期：">
                  </el-form-item>
                </el-col>
              </el-form>
            </div>
            
            <div class="other">
              <p>
                <span><b>备注</b></span>：此回执在确认收货盖章后，请将回执拍照或寄回，以便我司确认贵公司已收到货，谢谢配合
              </p>
              <p>
                <span><b>地址</b></span>：天津市津南区咸水沽镇津沽公路295号阿里云创新中心2楼B16室，张洁17622707055
              </p>
            </div>
        </el-dialog>
      </div>
  </div>
</template>
  <script>
    import BaseData from '@/assets/config/BaseData';

    import MDstorage from '@/api/mangeDevice/MangeDeviceStorage'
    import MDoutbound from '@/api/mangeDevice/MangeDeviceOutbound'
  
    export default {
      data() {
        return {
          baseUrl: BaseData.baseUrl,

          imgFormDialog:false,
          imgDialog:false,
          file:null,
          formList:{
            people:"",
            outPeople:"",
            outIp:"",
            outPhone:"",
            outProject:"",
            createTime:"",
          },
          formTable:[],
          dialogVisible:false,
          activeName: 'first',
          outHistory:[],
          inHistory:[],
          inPage:{
            total: 0,
            limit: 10,
            current: 1,
          },
          outPage:{
            total: 0,
            limit: 10,
            current: 1,
          },
          searchIn:"",
          imgId:"",
          imgsrc:"",
          queryBy:{
            customer:"",
            outProject:"",
            model:""
          }
        };
      },
      async created(){
        await this.InHistoryList()
        await this.OutHistoryList()
      },
      watch:{
     
      },
      methods: {
        imgFormDialogs(e){
          this.imgFormDialog = true
          this.imgsrc = e.photo
          console.log(this.imgsrc);
        },
        imgDialogClose(){
          console.log("关闭了");
          this.file = null
          this.imgId = ""
        },
        imageDialog(e){
          console.log(this.file);
          this.imgId = e.id
          this.imgDialog = true
        },
        async upload(){
            console.log(this.file);
            const formData = new FormData()
            // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
            formData.append('multipartFile',this.file)
            formData.append("id",  this.imgId);
            console.log(formData);
            const res = await MDoutbound.updateImg(formData)
            console.log(res);
            if(res) {
              this.imgDialog = false
              if(res.data.message == "添加成功！") {
                this.$message.success("添加成功")
                await this.OutHistoryList()
                return
              }
              this.$message.warning("添加失败")
              return
            }
        },
        changeFile(e){
            // 获取文件信息 e.target.files
            console.log(e.target.files[0]);
            this.file=e.target.files[0];
        },
        async export2ExcelIn() {
          const res = await MDstorage.exportStorage()
          console.log(res);
          if (res) {
            // 获取所有设备信息导出
            const resp1 = res.data.data.list1
            const resp2 = res.data.data.list2
            

            let filterVal1 = ["id","people","createTime","remark"]
            let filterVal2 = ["storageId","storageTime","deviceModel","deviceName","factoryName","sortName","depotName","num","unit","actionType"]

            // 一个对象代表一个sheet页
            let result = [
              {
                th: ["入库单号", "操作人", "创建时间", "备注"], //表头
                data: this.formatJson(filterVal1, resp1), //格式化的数据
                sheetTitle: "入库单号",// sheet名称
              },
              {
                th: ["所属单号","入库时间","设备型号", "设备名称","设备厂家","设备分类","入库仓库","入库数量","计量单位","入库类型"],
                data: this.formatJson(filterVal2, resp2),
                sheetTitle: "入库记录",
              },
            ];
            require.ensure([], () => {
              let { export2ExcelMultiSheet } = require("../../../introduce/Export2Excel");
              export2ExcelMultiSheet(result, "入库历史记录");
            });
          }
        },
        async export2ExcelOut() {
          const res = await MDoutbound.exportOutbound()
          if (res) {
            // 获取所有设备信息导出
            const resp1 = res.data.data.list1
            const resp2 = res.data.data.list2
            resp1.map(item => {
              if(item.state == 0){
                item.state="已发货"
              }else {
                item.state="已签收"
              }
            })
            let filterVal1 = ["id","people","createTime","actionType","awbNo","customer","outIp","outPeople","outPhone","outProject","state","remark"]
            let filterVal2 = ["outboundId","outboundTime","deviceModel","deviceName","factoryName","sortName","depotName","num","unit","remark"]

            // 一个对象代表一个sheet页
            let result = [
              {
                th: ["出库单号", "操作人", "创建时间", "出库类型", "运单号", "发货客户", "收货地址", "收货人", "收货电话", "项目名称", "状态", "备注"], //表头
                data: this.formatJson(filterVal1, resp1), //格式化的数据
                sheetTitle: "出库单号",// sheet名称
              },
              {
                th: ["所属单号","出库时间","设备型号", "设备名称","设备厂家","设备分类","出库仓库","出库数","计量单位","备注"],
                data: this.formatJson(filterVal2, resp2),
                sheetTitle: "出库记录",
              },
            ];
            require.ensure([], () => {
              let { export2ExcelMultiSheet } = require("../../../introduce/Export2Excel");
              export2ExcelMultiSheet(result, "出库历史记录");
            });
          }
        },
         // 格式化数据
        formatJson(filterVal, jsonData) {
          return jsonData.map((v) => filterVal.map((j) => v[j]));
        },
        look(e){
          console.log(e);
          e.createTime = e.createTime.substring(0,10)
          this.formList = e
          this.formTable = e.historyInfo
          this.dialogVisible = true
         },
        async checkIt(e){
          if (e.awbNo == "" || e.awbNo == null) {
            return this.$message.warning("运单号为空！")
          }
          console.log(e);
           const confirmRes  = await this.$confirm("请与客户确认是否签收, 是否继续?", "提示", {
            confirmButtonText: "签收",
            cancelButtonText: "取消",
            type: "success",
          }).catch(
            err => console.log(err)
          )
          if (confirmRes !== 'confirm') 
              return this.$message.info('已取消')
          let form = {
              id:e.id,
              state:1
          }
          const res = await MDoutbound.updateOne(form)
          console.log(res);
          if (res.data.message == "更新成功") {
            this.$message.success(res.data.message)
            await this.OutHistoryList()
          } else{
            this.$message.warning(res.data.message)
          }
        },
        async editNo(e){
          const confirmRes  = await this.$prompt('请输入运单号', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
            inputErrorMessage: '运单号不能为空'
          }).catch(
              err => console.log(err)
          )
          console.log(confirmRes);
          if (!confirmRes) 
            return this.$message.info('已取消')

          const {value} = confirmRes
          let form = {
              id:e.id,
              awbNo:value
          }
          console.log(form);
          const res = await MDoutbound.updateOne(form)
          console.log(res);
          if (res.data.message == "更新成功") {
            this.$message.success(res.data.message)
            await this.OutHistoryList()
          } else{
            this.$message.warning(res.data.message)
          }
        },
        handleClick(tab, event) {
          // console.log(tab, event);
        },
         //  入库记录
        async InHistoryList(){
            const res = await MDstorage.queryList(this.inPage)
            this.inHistory = res.data.data.list
            console.log(this.inHistory);
            this.inPage.total = res.data.data.total
        },
         //  出库记录
        async OutHistoryList(){
            const res = await MDoutbound.queryList(this.outPage)
            this.outHistory = res.data.data.list
            this.outPage.total = res.data.data.total
        }, 
        async clickQueryOut(){
          if (this.queryBy.customer || this.queryBy.model || this.queryBy.outProject) {
            if (this.queryBy.model) {
              await this.outhistorylistbymodel()
              return
            }
            let form = {customer:this.queryBy.customer,outProject:this.queryBy.outProject,...this.outPage}
            await this.queryListByCustomer(form)
            return
          }
          console.log(111);
          // await this.OutHistoryList()
          return
        },
         //  出库记录 客戶查詢
        async queryListByCustomer(e){
          const { data: res } = await MDoutbound.queryListByCustomer(e)
          this.outHistory = res.data.list
          this.outPage.total = res.data.total
        },
        async outhistorylistbymodel(){
          let form = {deviceModel:this.queryBy.model,...this.outPage}
          const res = await MDoutbound.queryListByModel(form)
          this.outHistory = res.data.data.list
          this.outPage.total = res.data.data.total
        },
        async inhistorylistbymodel(){
          if (this.searchIn) {
            let form = {deviceModel:this.searchIn,...this.inPage}
            const res = await MDstorage.queryListByModel(form)
            this.inHistory = res.data.data.list
            this.inPage.total = res.data.data.total
          }else{
            await this.InHistoryList()
          }
        },
       
        // 分页
        async inCurrentPage(value) {
            await this.InHistoryList()
        },
        // 分页
        async outCurrentPage(value) {
            await this.OutHistoryList()
        },
        async restInList(){
          this.searchIn = ""
          await this.InHistoryList()
        },
        async restOutList(){
          this.queryBy = {}
          await this.OutHistoryList()
        }
      },
    };
  </script>
  <style lang="scss" scoped>
  .sigdate{
    padding: 10px 0 0 10px;
    font-weight: bolder;
  }
  .other{
    border: 1px rgb(228, 228, 228) solid;
    line-height: 27px;
    padding:10px 0 0 10px
  }
  </style>