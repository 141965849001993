<template>
  <div>
    <div style="width: 100%; margin-top: 10px">
      <el-form ref="form" :model="form" :rules="formRules" label-width="110px">
        <el-form-item label="出库类型" prop="actionType">
          <el-select v-model="form.actionType" placeholder="请选择出库类型">
            <el-option value="销售出库"></el-option>
            <el-option value="样品出库"></el-option>
            <el-option value="采购退货"></el-option>
            <el-option value="借用出库"></el-option>
            <el-option value="维修出库"></el-option>
            <el-option value="赠送出库"></el-option>
            <el-option value="其他出库"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库仓库" prop="depotId">
          <el-select v-model="form.depotId" placeholder="请选择出库仓库" @change="depotChange">
            <el-option
              v-for="item in depotList"
              :key="item.id"
              :label="item.depotName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库时间" prop="outboundTime">
          <el-date-picker
            v-model="form.outboundTime"
            align="right"
            type="date"
            placeholder="选择日期"
            @input="change($event)"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出库项目" prop="outProject">
          <el-input v-model="form.outProject"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="outIp">
          <el-input v-model="form.outIp"></el-input>
        </el-form-item>
        <el-form-item label="收货联系人"  prop="outPeople">
          <el-input v-model="form.outPeople"></el-input>
        </el-form-item>
        <el-form-item label="收货电话" prop="outPhone">
          <el-input v-model="form.outPhone"></el-input>
        </el-form-item>
        <el-form-item label="发货单号">
          <el-input v-model="form.awbNo"></el-input>
        </el-form-item>
        <el-form-item label="发货客户" prop="customer">
          <el-input v-model="form.customer"></el-input>
        </el-form-item>
        <el-form-item label="出库状态">
          <el-radio-group v-model="form.state">
            <el-radio :label="0">已发货</el-radio>
            <el-radio :label="1">已签收</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="出库产品">
          <el-button type="primary" @click="dialogTableVisible = true">
            选择产品
          </el-button>
        </el-form-item>
        <el-form-item label="出库展示">
          <el-table :key="isUpdate" :data="tableData" border style="width: 100%" show-summary>
            <el-table-column
              prop="deviceModel"
              label="设备型号"
            ></el-table-column>
            <el-table-column prop="deviceOut" label="数量"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="row">
                <el-button type="primary" size="mini" @click="addNum(row.row)"
                  >编辑</el-button
                >
                <el-button type="danger" size="mini" @click="delNum(row.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        
        <el-form-item label="出库备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-dialog title="产品选择" :visible.sync="dialogTableVisible" :close-on-click-modal="false"  @closed="checkOvers">
        <device-entry ref="child" @getDeviceDetail="getData" :btnShow="true"></device-entry>
        <el-button type="primary" @click="checkOver">选择完成</el-button>
      </el-dialog>

      <el-dialog title="编辑数量" :visible.sync="numDialog" width="35%" :close-on-click-modal="false">
        <div style="width: 70%">
          <el-form
            :model="numForm"
            ref="numForm"
            :rules="numRules"
            label-width="80px"
          >
            <el-form-item label="设备型号" prop="deviceModel">
              <el-input
                v-model="numForm.deviceModel"
                disabled
                placeholder="请输入设备型号"
              ></el-input>
            </el-form-item>
            <el-form-item label="数量" prop="deviceOut">
              <el-input
                v-model="numForm.deviceOut"
                type="number"
                @input="change($event)"
                placeholder="请输入设备出库数量"
                onkeyup="value=value.replace(/[^\d]/g, '').replace(/^0{1,}/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="numForm.remark"></el-input>
            </el-form-item>
            
            <el-form-item label="现有库存">
              <el-table :data="nowData" border style="width: 100%" show-summary empty-text="仓库无库存">
                <el-table-column prop="depotName" label="存放仓库"></el-table-column>
                <el-table-column prop="count" label="数量"></el-table-column>
              </el-table>
            </el-form-item>
           
            <el-form-item>
              <el-button type="primary" @click="finish('numForm')"
                >确 定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>    
  </div>
</template>
<script>
import DeviceEntry from "./DeviceEntry.vue";
import DeviceCount from "@/api/mangeDevice/DeviceCount";
import validates from '@/../utils/rules';
export default {
  components: {DeviceEntry},
  props: {
    depotList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      isUpdate:true,
      numForm: {
        id: "",
        deviceModel: "",
        deviceOut: null,
        remark:"",
      },
      numRules: {
        deviceModel: [
          {required: true, message: "请输入设备型号", trigger: "blur"},
        ],
        deviceOut: [
          {required: true, message: "请输入数量", trigger: "blur"}
          ],
      },
      form: {
        outboundTime:new Date().Format("yyyy-MM-dd hh:mm:ss"),
        rack: "", // 货架
        depotId: "", // 仓库id
        actionType: "", // 出库类型
        outProject: "", // 出库项目
        outIp: "", // 收货地址
        outPeople: "", // 收货联系人
        outPhone: "", // 收货联系人
        awbNo: "", // 发货单号
        state: 0, // 状态
        remark: "", // 备注
        customer:"",
      },
      formRules: {
        actionType: [{required: true, message: "请选择入库类型", trigger: "change"}],
        depotId: [{required: true, message: "请选择仓库", trigger: "change"}],
        outProject: [{required: true, message: "请输入出库项目", trigger: "blur"}],
        outIp: [{required: true, message: "请输入收货地址", trigger: "blur"}],
        outPeople: [{required: true, message: "请输入收货人", trigger: "blur"}],
        outPhone:[{required: true, validator: validates.checkPhone, trigger: 'blur' }],
        
        customer: [{required: true, message: "请输入发货客户", trigger: "blur"}],
      },
      dialogTableVisible: false,
      numDialog: false,
      tableData: [],
      nowData:[],
      showDetailList:[] , // 处理选中的数据
    };
  },
  methods: {
    getData(val) {
      this.showDetailList = JSON.parse(JSON.stringify(val))
      console.log(this.showDetailList); // 这个是选择的设备型号list
      // this.tableData = val;
      // this.tableData.map((item) => {
      //   item.remark = ""
      //   if (item.deviceOut) {
      //     return;
      //   } else {
      //     item.deviceOut = 0;
      //   }
      // });
    },
    concatArr(val){
      // const aaa = Array.from(new Set(this.tableData.concat(val)))
      this.tableData.push(...val)
      this.tableData = this.unique(this.tableData);
      console.log( this.tableData); 

      this.tableData.map((item) => {
        item.remark = ""
        item.outboundTime=new Date().Format("yyyy-MM-dd hh:mm:ss");
        if (item.deviceOut) {
          return;
        } else {
          item.deviceOut = 0;
        }
      });
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    checkOver() {
      // 点击选择完成 处理选择的数据
      this.concatArr(this.showDetailList)

      this.dialogTableVisible = false;
    },
    checkOvers(){
      console.log("关闭了");
      this.$refs.child.getMangeDeviceDetailList(); // 选择完产品 关闭弹框刷新页面
    },
    async addNum(e) {
      if(!this.form.depotId){
        return this.$message.warning("请选择出库仓库")
      }
      let form= {
        detailId:e.id
      }
      const res = await DeviceCount.queryCount(form)
      console.log(res.data.data.list);
      this.nowData = res.data.data.list
      this.numDialog = true;
      this.numForm = e;
    },
    delNum(e) {
      // 获取元素下标
      var index = this.tableData.indexOf(e);
      // 使用splice进行移除，删除第index位置的指定个数的元素
      if (index > -1) {
        this.tableData.splice(index, 1);
      }
    },
    depotChange(){
      this.tableData = []
    },
    async finish(numForm) {
      var valid = await this.$refs[numForm].validate();
      if (valid) {
        console.log("在这里加判断 是不是超出库存现有");
        if(!this.nowData){
          this.numForm.deviceOut = 0
          this.numDialog = false;
          this.isUpdate = !this.isUpdate;
          return this.$message.warning("仓库无库存")
        }
        const aaa = this.nowData.filter(item => item.depotId == this.form.depotId)
        console.log(aaa);
        if(aaa.length > 0){
          console.log("仓库有库存");
          if(aaa[0].count >= this.numForm.deviceOut){
            var index = this.tableData.indexOf(this.numForm);
            this.$set(this.tableData, index, this.numForm);
            this.numDialog = false;
            this.isUpdate = !this.isUpdate;
            return
          }else {
            return this.$message.warning("超出现有库存，请重新编辑")
          }
        }
        console.log("仓库无库存");
        this.numForm.deviceOut = 0
        this.numDialog = false;
        this.isUpdate = !this.isUpdate;
        return this.$message.warning("已选仓库下无该产品")
        // this.$refs[numForm].resetFields()
      } else {
        console.log("error submit!!");
        return;
      }
    },
    async restForm(){
      this.$refs.form.resetFields()
      this.tableData=[]
    },
    async onSubmit(form) {
      console.log(this.form);
      const valid = await this.$refs[form].validate();
      if (valid) {
        if (this.tableData.length <= 0) {
          return this.$message.warning("请选择产品");
        }
        let typeMess = 0;
        this.tableData.map((item) => {
          if (item.deviceOut <= 0) {
            typeMess = 1;
          }
        });
        if (typeMess === 1) {
          return this.$message.warning("产品数量不能为0");
        }

        const data = []; //   把id 和入库数量 放进这里
        const newList = []; // 合并一下 把data 和 入库类型+入库仓库 备注合并一下

        console.log(this.tableData);
        // 筛选出产品ID 和入库数量
        this.tableData.map((item) => {
          data.push({
            detailId: item.id,
            deviceOut: item.deviceOut,
            remark: item.remark,
          });
        });
        // 产品ID 和入库数量 合并 类型和仓库 返回newList
        data.map(item => {
          item = {...{depotId:this.form.depotId},...item,};
          newList.push(item);
          return;
        });
        console.log(newList);
        this.form.jsonArrayList = newList
        console.log(this.form);

        // 接口
        const res = await DeviceCount.outDeviceList(this.form);
        console.log(res);
        if (res.data.message == "出库成功") {
          this.$message.success(res.data.message);
        } else {
          this.$message.warning(res.data.message);
        }

        //初始化
        this.$refs[form].resetFields()
        this.tableData=[]
        await this.$refs.child.getMangeDeviceDetailList();
        await this.$emit("getDevice"); // 刷新页面
        await this.$emit("dialogClose"); // 关闭弹框
      } else {
        console.log("error submit!!");
        return this.$message.warning("校验失败");
      }
    },

    openDialog() {
      this.dialogTableVisible = true;
    },
    change(e) {
      this.$forceUpdate();
    },
    
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner {
    width: 300px;
}
</style>
