import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDeviceDetailExcel'

export default {
    importExcelFactory(params) {
        return request({
            url: `${api_name}/importExcelFactory`,
            method: 'post',
            data: params,
            headers: { "Content-Type": "multipart/form-data" }
        })
    },
    importExcelDevice(params) {
        return request({
            url: `${api_name}/importExcelDevice`,
            method: 'post',
            data: params,
            headers: { "Content-Type": "multipart/form-data" }
        })
    },
    importExcelModel(params) {
        return request({
            url: `${api_name}/importExcelModel`,
            method: 'post',
            data: params,
            headers: { "Content-Type": "multipart/form-data" }
        })
    },
    importExcelSort(params) {
        return request({
            url: `${api_name}/importExcelSort`,
            method: 'post',
            data: params,
            headers: { "Content-Type": "multipart/form-data" }
        })
    },
}