<template>
  <div>
    <div style="width: 100%; margin-top: 10px">
      <el-form ref="form" :model="form" :rules="formRules" label-width="80px">
        <el-form-item label="入库类型" prop="actionType">
          <el-select v-model="form.actionType" placeholder="请选择入库类型">
            <el-option label="期初入库" value="期初入库"></el-option>
            <el-option label="采购入库" value="采购入库"></el-option>
            <el-option label="样品入库" value="样品入库"></el-option>
            <el-option label="销售退货" value="销售退货"></el-option>
            <el-option label="归还入库" value="归还入库"></el-option>
            <el-option label="其他入库" value="其他入库"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库" prop="depotId">
          <el-select v-model="form.depotId" placeholder="请选择入库仓库">
            <el-option
              v-for="item in depotList"
              :key="item.id"
              :label="item.depotName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库产品">
          <el-button type="primary" @click="dialogTableVisible = true">
            选择产品
          </el-button>
        </el-form-item>
        <el-form-item label="入库展示">
          <el-table  :key="isUpdate" :data="tableData" border style="width: 100%" show-summary>
            <el-table-column
              prop="deviceModel"
              label="设备型号"
            ></el-table-column>
            <el-table-column prop="deviceIn" label="数量"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="row">
                <el-button type="primary" size="mini" @click="addNum(row.row)"
                  >编辑</el-button
                >
                <el-button type="danger" size="mini" @click="delNum(row.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="入库备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-dialog title="产品选择" :visible.sync="dialogTableVisible" :close-on-click-modal="false" @closed="checkOvers">
        <device-entry ref="child" @getDeviceDetail="getData" :btnShow="true"></device-entry>
        <el-button type="primary" @click="checkOver">选择完成</el-button>
      </el-dialog>

      <el-dialog title="编辑数量" :visible.sync="numDialog" width="30%" :close-on-click-modal="false">
        <div style="width: 50%">
          <el-form
            :model="numForm"
            ref="numForm"
            :rules="numRules"
            label-width="80px"
          >
            <el-form-item label="设备型号" prop="deviceModel">
              <el-input
                v-model="numForm.deviceModel"
                disabled
                placeholder="请输入设备型号"
              ></el-input>
            </el-form-item>
            <el-form-item label="数量" prop="deviceIn">
              <el-input
                v-model="numForm.deviceIn"
                type="number"
                @input="change($event)"
                placeholder="请输入设备型号"
              ></el-input>
            </el-form-item>
            <el-form-item label="入库时间" prop="storageTime">
              <el-date-picker
                v-model="numForm.storageTime"
                align="right"
                type="date"
                placeholder="选择日期"
                @input="change($event)"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="finish('numForm')"
                >确 定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>    
  </div>
</template>
<script>
import DeviceEntry from "./DeviceEntry.vue";
import DeviceCount from "@/api/mangeDevice/DeviceCount";
export default {
  components: {DeviceEntry},
  props: {
    depotList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      isUpdate:true,
      numForm: {
        id: "",
        deviceModel: "",
        deviceIn: 0,
        storageTime:"",
      },
      numRules: {
        deviceModel: [
          {required: true, message: "请输入设备型号", trigger: "blur"},
        ],
        deviceIn: [{required: true, message: "请输入数量", trigger: "blur"}],
        storageTime: [{required: true, message: "请选择时间", trigger: "blur"}],
      },
      form: {
        rack: "",
        depotId: "",
        actionType: "",
        remark: "",
      },
      formRules: {
        actionType: [
          {required: true, message: "请选择入库类型", trigger: "change"},
        ],
        depotId: [{required: true, message: "请选择仓库", trigger: "change"}],
      },
      dialogTableVisible: false,
      numDialog: false,
      tableData: [],
      showDetailList:[], // 处理选中的数据
    };
  },
  methods: {
    getData(val) {
      this.showDetailList = JSON.parse(JSON.stringify(val))
      this.concatArr(this.showDetailList)
    },
    concatArr(val){
      // const aaa = Array.from(new Set(this.tableData.concat(val)))
      this.tableData.push(...val)
      this.tableData = this.unique(this.tableData);
      console.log( this.tableData); 

      this.tableData.map((item) => {
        item.storageTime=new Date().Format("yyyy-MM-dd hh:mm:ss");
        if (item.deviceIn) {
          return;
        } else {
          item.deviceIn = 0;
        }
      });
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    checkOver() {
      // 点击选择完成 处理选择的数据
      this.concatArr(this.showDetailList)
      
      this.dialogTableVisible = false;
    },
    checkOvers(){
      this.$refs.child.getMangeDeviceDetailList(); // 选择完产品 关闭弹框刷新页面
    },
    addNum(e) {
      this.numDialog = true;
      this.numForm = e;
    },
    delNum(e) {
      // 获取元素下标
      var index = this.tableData.indexOf(e);
      // 使用splice进行移除，删除第index位置的指定个数的元素
      if (index > -1) {
        this.tableData.splice(index, 1);
      }
    },
    async finish(numForm) {
      var valid = await this.$refs[numForm].validate();
      if (valid) {
        var index = this.tableData.indexOf(this.numForm);
        this.$set(this.tableData, index, this.numForm);
        this.numDialog = false;
        this.isUpdate = !this.isUpdate;
        console.log(this.tableData);
      } else {
        console.log("error submit!!");
        return;
      }
    },
    async restForm(){
      this.$refs.form.resetFields()
      this.tableData=[]
    },
    async onSubmit(form) {
      const valid = await this.$refs[form].validate();
      if (valid) {
        if (this.tableData.length <= 0) {
          return this.$message.warning("请选择产品");
        }
        let typeMess = 0;
        this.tableData.map((item) => {
          if (item.deviceIn <= 0) {
            typeMess = 1;
          }
        });
        if (typeMess === 1) {
          return this.$message.warning("产品数量不能为0");
        }

        const data = []; //   把id 和入库数量 放进这里
        const newList = []; // 合并一下 把data 和 入库类型+入库仓库 备注合并一下

        console.log(this.tableData);
        // 筛选出产品ID 和入库数量
        this.tableData.map((item) => {
          data.push({
            detailId: item.id,
            deviceIn: item.deviceIn,
            storageTime: item.storageTime,
          });
        });
        // 产品ID 和入库数量 合并 类型和仓库 返回newList
        data.map((item) => {
          item = {...item, ...this.form};
          newList.push(item);
          return;
        });
        console.log(newList);
        let form1 = {
          jsonArrayList: newList,
        };
        console.log(form1);
        const res = await DeviceCount.inDeviceList(form1);
        if (res.data.message == "添加成功") {
          this.$message.success(res.data.message);
        } else {
          this.$message.warning(res.data.message);
        }
        this.$refs[form].resetFields()
        this.tableData=[]
        await this.$refs.child.getMangeDeviceDetailList();
        await this.$emit("getDevice"); // 刷新页面
        this.$emit("dialogClose"); // 关闭弹框
      } else {
        console.log("error submit!!");
        return this.$message.warning("校验失败");
      }
    },

    openDialog() {
      this.dialogTableVisible = true;
    },
    change(e) {
      this.$forceUpdate();
    },
    
  },
};
</script>
