<template>
  <div id="app" >
    <!-- 公司结构 -->
    <div>
      <el-input placeholder="输入仓库名进行搜索..." v-model="searchDepot" prefix-icon="el-icon-search"  style="margin: 10px 10px 5px 0; width: 300px">
      </el-input>
      <el-button type="primary" @click="confirmAdd">添加仓库</el-button>
       <div>
         <el-table 
          border
          :data="allDeviceDepot" 
          style="width: 40%; padding-bottom: 20px"
        >
            <el-table-column type="index" width="50" label="序号"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180"> </el-table-column>
            <el-table-column prop="depotName" label="仓库名称" width="180"> </el-table-column>
            <el-table-column label="操作"  fixed="right">
              <template slot-scope="row">
                  <el-button
                    size="medium"
                    type="primary"
                    @click="confirmEdit(row.row)"
                  >编辑</el-button>
                   <el-button
                    size="medium"
                    type="danger"
                    @click="delDepot(row.row)"
                  >删除</el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <el-pagination
        :current-page.sync="pages.current"
        :page-size="pages.limit"
        background
        :total="pages.total"
        layout="total, prev, pager, next, jumper"
        style=" position: fixed; bottom:20px;padding-top: 10px;  text-align: center"
        @current-change="currentPage"
      ></el-pagination>
    </div>
    <div>
      <el-dialog
        :visible.sync="depotDialog"
        width="30%"
       >
        <div style="width:70%">
          <el-form :model="depotForm" ref="depotForm" :rules="depotRules" label-width="80px">
            <el-form-item label="仓库名称" prop="depotName">
              <el-input v-model="depotForm.depotName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button @click="restForm('depotForm')">取 消</el-button>
          <el-button type="primary" @click="confirmDepot('depotForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DeviceDepot from "@/api/mangeDevice/DeviceDepot"

export default {
  data() {
    return {
      searchDepot:"",
      depotForm:{
        id:"",
        depotName:""
      },
      depotRules:{
        depotName: [
          { required: true, message: '请输入仓库名称', trigger: 'blur' }
        ],
      },
      pages:{
        total: 0,
        limit: 10,
        current: 1,
      },
      allDeviceDepot:[],
      depotDialog:false
    };
  },

  async created() {
    await this.queryDeviceDepot()
  },
  watch: {
  },
  methods: {
    async restList(){
      this.queryDeviceDepot()
    },
    async queryDeviceDepot(){
     this.$store.dispatch('depot/queryDepotAsync',this.pages);
     this.allDeviceDepot = this.$store.state.depot.depotList
     this.pages.total = this.$store.state.depot.total
    },
    async currentPage(){
      await this.queryDeviceDepot()
    },
    async confirmDepot(depotForm){
      const valid = await this.$refs[depotForm].validate()
      if (valid) {
        if (this.depotForm.id) {
          // 有id 修改
          await this.editDepot()
        }else {
          // 没id 新增
          await this.addDepot()
        }
        await this.queryDeviceDepot()
        await this.restForm(depotForm)
        console.log(this.depotForm);
      } else {
        console.log('error submit!!');
        return false;
      }
    },
    async addDepot(){
        const res = await DeviceDepot.addDepot(this.depotForm)
        console.log(res);
        if (res.data.message == "添加成功") {
          this.$message.success(res.data.message)
          // 
          this.$emit( 'queryDepot')
        }else{
          this.$message.warning(res.data.message)
        }
    },
    async editDepot(){
        const res = await DeviceDepot.updateDepot(this.depotForm)
        console.log(res);
        if (res.data.message == "修改成功") {
          this.$message.success(res.data.message)
          this.$emit( 'queryDepot')

        }else{
          this.$message.warning(res.data.message)
        }
        this.depotForm.id = ""
        this.depotForm.depotName = ""
    },
    confirmAdd(){
      this.depotDialog = true
    },
    confirmEdit(e){
      this.depotForm.id = e.id
      this.depotForm.depotName = e.depotName
      this.depotDialog = true
    },
    async delDepot(e){
      const confirmRes  = await this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(
        err => console.log(err)
      )
      if (confirmRes !== 'confirm') 
          return this.$message.info('已经取消删除')

      const res = await DeviceDepot.delDepot(e)
      console.log(res);
      if (res.data.message == "删除成功") {
        this.$message.success(res.data.message)
        this.$emit( 'queryDepot')
      }else{
        this.$message.warning(res.data.message)
      }
      await this.queryDeviceDepot()
    },
    async restForm(depotForm){
      this.depotDialog = false
      this.$refs[depotForm].resetFields()
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 10px 5px;
}
// input type=number时 去掉后面上下按钮
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.add,
.del {
  font-size: 16px;
  padding: 2px 5px;
}

/deep/.el-dialog__title {
  font-size: 24px;
  font-weight: 600;
  color: #555;
}

.company-info {
  width: 100%;
  box-sizing: border-box;
  //   border: 1px solid red;
  display: flex;

  // height: 100px;
  .left {
    width: 30%;
    height: 282px;

    // border: 1px solid #aaa;
    .img {
      margin-left: 20%;
      box-sizing: border-box;
      width: 70%;
      height: 282px;
      //   border: 1px solid #aaa;
    }
  }

  .right {
    width: 70%;
    // border: 1px solid rgb(247, 110, 110);
  }
}

.inpt {
  width: 40%;
  //   opacity: 0.2;
  display: none;
}

.upload {
  margin: 10px;
  cursor: pointer;
}

.icon:hover {
  color: aquamarine;
}
</style>