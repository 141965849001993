import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDeviceCount'

export default {
    // 批量添加
    inDeviceList(mangeDeviceCount) {
        return request({
            url: `${api_name}/inDeviceList`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    // 批量出库
    outDeviceList(mangeDeviceCount) {
        return request({
            url: `${api_name}/outDeviceList`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    //  不分页 
    getDevice(mangeDeviceCount) {
        return request({
            url: `${api_name}/getDevice`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    getDeviceListByModel(mangeDeviceCount) {
        return request({
            url: `${api_name}/getDeviceListByModel`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    getDeviceListByDevice(mangeDeviceCount) {
        return request({
            url: `${api_name}/getDeviceListByDevice`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    getDeviceListByFactory(mangeDeviceCount) {
        return request({
            url: `${api_name}/getDeviceListByFactory`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    // 分页
    getDeviceList(mangeDeviceCount) {
        return request({
            url: `${api_name}/getDeviceList`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    outDevice(mangeDeviceCount) {
        return request({
            url: `${api_name}/outDevice`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    queryCount(mangeDeviceCount) {
        return request({
            url: `${api_name}/queryCount`,
            method: 'post',
            data:mangeDeviceCount
        })
    },
    allQueryByModel(mangeDeviceCount){
        return request({
            url: `${api_name}/allQueryByModel`,
            method: 'post',
            data:mangeDeviceCount
        })
    }

}