import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDeviceDetail'

export default {
    // 公众号的
    getModelList(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getModelList`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },

    // 历史记录 查全部
    getAll() {
        return request({
            url: `${api_name}/getAll`,
            method: 'post',
        })
    },
    getMangeDeviceDetail(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getMangeDeviceDetail`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },

    getMangeDeviceDetailList(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getMangeDeviceDetailList`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },

    addMangeDeviceDetail(mangeDeviceDetail) {
        return request({
            url: `${api_name}/addMangeDeviceDetail`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    editDetail(mangeDeviceDetail) {
        return request({
            url: `${api_name}/editDetail`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    delDetail(mangeDeviceDetail) {
        return request({
            url: `${api_name}/delDetail`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    getDetailListByModel(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getDetailListByModel`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    getDetailListByFactory(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getDetailListByFactory`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    getDetailListByFactoryAlias(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getDetailListByFactoryAlias`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    getDetailListByDevice(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getDetailListByDevice`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },
    getDetailListByDeviceAlias(mangeDeviceDetail) {
        return request({
            url: `${api_name}/getDetailListByDeviceAlias`,
            method: 'post',
            data:mangeDeviceDetail
        })
    },

   

}