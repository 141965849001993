<template>
  <div>
    <el-row style="width: 100%; height: 26.5%">
      <el-col
        style="
          width: 90%;
          height: 94%;
          margin-left: 5%;
          margin-top: 2%;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <div class="scroller">
          <div
            v-for="item in departmentList"
            :key="item.id"
            class="scrollbar-demo-item"
            style="margin-top: 2%"
          >
            <div
              style="
                width: 30%;
                text-align: left;
                font-size: 24px;
                color: #ffffff;
              "
            >
              {{ item.name }}
            </div>
            <div
              style="
                width: 50%;
                text-align: left;
                font-size: 24px;
                color: #ffffff;
              "
            >
              {{ item.address }}
            </div>
            <div style="text-align: right; padding-top: 1%">
              <el-button class="detail-button" @click="aaa(item.id)">查看详情</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      departmentList: [
        {
          id: "1",
          name: "A客户",
          address:"天津市津南区咸水沽镇阿里云创新中心B16"
        },
        {
          id: "2",
          name: "B客户",
          address:"天津市津南区咸水沽镇阿里云创新中心B16"
        },
      ],
    };
  },
  methods: {
    aaa(e){
      console.log(e);
    }
  },
};
</script>
<style scoped>

.scroller {
  height: 600px;
  overflow-y: auto;
}

.scrollbar-demo-item {
  width: 90%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5em;
  text-align: left;
  border-bottom: #fffefe 1px dashed;
  color: #fffefe;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}
</style>
