import request from '@/../utils/request'
const api_name = '/iotechserver/mangeDeviceFactory'

export default {

    addFactory(mangeDeviceFactory) {
        return request({
            url: `${api_name}/addFactory`,
            method: 'post',
            data:mangeDeviceFactory
        })
    },
    getFactoryList(mangeDeviceFactory) {
        return request({
            url: `${api_name}/getFactoryList`,
            method: 'post',
            data:mangeDeviceFactory
        })
    },
    factoryTree() {
        return request({
            url: `${api_name}/factoryTree`,
            method: 'post',
        })
    },

}