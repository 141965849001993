<template>
  <div id="app" class="url">
    <!-- 公司结构 -->
    <div>
      <div style="display: flex">
        <div>
          <el-input
            :placeholder="inputplace"
            v-model="searchDetail"
            class="input-with-select"
          >
            <el-select v-model="select" slot="prepend" placeholder="请选择">
              <el-option label="厂家名称" value="1"></el-option>
              <el-option label="设备名称" value="2"></el-option>
              <el-option label="设备型号" value="3"></el-option>
              <el-option label="厂家别名" value="4"></el-option>
              <el-option label="设备别名" value="5"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="selectBy"
            ></el-button>
          </el-input>
        </div>
        <div style="margin:0 10px">
          <el-button type="primary" @click="restList" >重置</el-button>
        </div>
        <div v-if="!btnShow">
          <el-button type="primary" @click="openDialog">添加产品</el-button>
          <el-button type="primary"  @click="fileDialogVisible = true">导入excel文件</el-button>
          <el-button @click="export2Excel" type="primary">导出</el-button>
        </div>
      </div>
      <div>
        <el-table
          class="table"
          border
          :data="allDeviceDetailList"
          style="width: 100%; padding-bottom: 20px; margin-top: 10px"
          @selection-change="checkDeviceDetail"
        >
          <el-table-column
            v-if="btnShow"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column prop="factoryName" label="厂家名称" width="auto">
          </el-table-column>
          <el-table-column prop="deviceName" label="设备名称" width="180">
          </el-table-column>
          <el-table-column prop="deviceModel" label="设备型号" width="180">
          </el-table-column>
          <el-table-column prop="sortName" label="分类名称" width="180">
          </el-table-column>
          <el-table-column prop="unit" label="计量单位" width="80">
          </el-table-column>
          <el-table-column prop="warn" label="报警数量" width="80">
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="180">
          </el-table-column>
          <el-table-column label="操作" fixed="right" v-if="!btnShow">
            <template slot-scope="row">
              <el-button
                size="medium"
                type="primary"
                @click="editDeviceDetail(row.row)"
                >编辑</el-button
              >
              <el-button
                size="medium"
                type="danger"
                @click="delDeviceDetail(row.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin:40px 0 0 0">
        <el-pagination
        :current-page.sync="pages.current"
        :page-size="pages.limit"
        background
        :total="pages.total"
        layout="total, prev, pager, next, jumper"
        style="
           text-align: center;
        "
          @current-change="currentPage"
        ></el-pagination>

      </div>
    
    </div>

    <div class="dialogClass">
      <el-dialog
        :title="detailTitle"
        @closed="dialogclosed"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="35%"
      >
        <div style="width: 70%">
          <el-form
            :model="detailForm"
            :rules="detailFormRule"
            label-width="80px"
            ref="detailForm"
          >
            <el-form-item label="厂家名称" prop="factoryId" v-if="!editHiden">
              <el-select
                @change="getDevice"
                v-model="detailForm.factoryId"
                filterable
                placeholder="输入别名或全称筛选"
                :filter-method="factoryFilter"
              >
                <el-option
                  v-for="item in filterFactoryList"
                  :key="item.id"
                  :label="item.factoryName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button
                icon="el-icon-plus"
                @click="dialogVisible1 = true"
              ></el-button>
            </el-form-item>

            <el-form-item label="设备名称" prop="deviceId" v-if="!editHiden">
              <el-select
                v-model="detailForm.deviceId"
                filterable
                placeholder="输入别名或全称筛选"
                :filter-method="deviceFilter"
                @change="$forceUpdate() "
              >
                <el-option
                  v-for="item in filterDeviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" @click="addDevice"></el-button>
            </el-form-item>

            <el-form-item label="设备型号" prop="deviceModel" v-if="!editHiden">
              <el-input
                v-model="detailForm.deviceModel"
                placeholder="请输入"
              ></el-input>
            </el-form-item>

            <el-form-item label="分类名称" prop="" v-if="!editHiden">
              <el-input
                @focus="dialogSort = true"
                v-model="detailForm.deviceSortName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>

            <el-form-item label="计量单位" prop="unit">
              <el-input
                v-model="detailForm.unit"
                placeholder="请输入"
              ></el-input>
            </el-form-item>

            <el-form-item label="报警数量" prop="warn">
              <el-input
                v-model="detailForm.warn"
                placeholder="请输入"
                type="number"
              ></el-input>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="detailForm.remark"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancalDeviceDetal('detailForm')"
              >取 消</el-button
            >
            <el-button type="primary" @click="addDeviceDetail('detailForm')"
              >确 定</el-button
            >
          </span>
        </div>
      </el-dialog>

      <el-dialog title="添加厂家" :visible.sync="dialogVisible1" width="30%">
        <el-form ref="form" :model="form" :rules="formRule" label-width="80px">
          <el-form-item label="厂家名称" prop="factoryName">
            <el-input
              v-model="form.factoryName"
              placeholder="请输入厂家名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="厂家别名" prop="factoryAlias">
            <el-input
              v-model="form.factoryAlias"
              placeholder="请输入厂家名称"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="addNewDeviceFactory('form')"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <el-dialog title="添加设备" :visible.sync="dialogVisible2" width="30%">
        <el-form
          ref="formDevice"
          :model="formDevice"
          label-width="80px"
          :rules="formDeviceRule"
        >
          <el-form-item label="设备名称" prop="deviceName">
            <el-input
              v-model="formDevice.deviceName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备别名" prop="deviceAlias">
            <el-input
              v-model="formDevice.deviceAlias"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="addNewDevice('formDevice')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="导入模板文件"
        :visible.sync="fileDialogVisible"
        width="50%"
        :close-on-click-modal="false"
        :before-close="handleDialogClose"
        center
      >
        <div style="margin-bottom: 10px">
          <span>
            <i class="el-icon-bell"></i>
            上传文件只能为excel文件，且为xlsx,xls格式
          </span>
        </div>
        <div style="display: flex; flex: 1">
          <div style="margin-right: 10px">
            <el-upload
              style="min-height: 40vh"
              ref="upload"
              name="file"
              :limit="1"
              :auto-upload="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              action
              :on-exceed="handleExceed"
              :file-list="filelist"
              :on-change="factoryChansge"
            >
              <el-button slot="trigger" size="small" type="primary">{{
                factorybtn.message
              }}</el-button>
            </el-upload>
          </div>
          <div style="margin-right: 10px">
            <el-upload
              style="min-height: 40vh"
              ref="upload"
              name="file"
              :limit="1"
              :auto-upload="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              action
              :on-exceed="handleExceed"
              :file-list="filelist"
              :on-change="handleDevice"
            >
              <el-button slot="trigger" size="small" type="primary">{{
                devicebtn.message
              }}</el-button>
            </el-upload>
          </div>
          <div style="margin-right: 10px">
            <el-upload
              style="min-height: 40vh"
              ref="upload"
              name="file"
              :limit="1"
              :auto-upload="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              action
              :on-exceed="handleExceed"
              :file-list="filelist"
              :on-change="modelChansge"
            >
              <el-button slot="trigger" size="small" type="primary">{{
                modelbtn.message
              }}</el-button>
            </el-upload>
          </div>
          <div style="margin-right: 10px">
            <el-upload
              style="min-height: 40vh"
              ref="upload"
              name="file"
              :limit="1"
              :auto-upload="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              action
              :on-exceed="handleExceed"
              :file-list="filelist"
              :on-change="sortChansge"
            >
              <el-button slot="trigger" size="small" type="primary">{{
                sortbtn.message
              }}</el-button>
            </el-upload>
          </div>
        </div>
        <!--此处可以写导入时出现的详细错误信息，包含第几行出现问题显示出来-->
        <div v-for="item in errmesg" :key="item.message" class="text item">
          {{ item.id ? `ID为：` + item.id + `，名称重复` : "" }}
        </div>
        <div
          v-for="items in nullFactory"
          :key="items.message"
          class="text item"
        >
          {{ items ? `厂家ID为：` + items + `，不存在` : "" }}
        </div>
        <div v-for="items in nullDevice" :key="items.message" class="text item">
          {{ items ? `设备ID为：` + items + `，不存在` : "" }}
        </div>
        <div v-for="items in nullSort" :key="items.message" class="text item">
          {{ items ? `分类ID为：` + items + `，不存在` : "" }}
        </div>
      </el-dialog>
      <el-dialog title="选择分类" :visible.sync="dialogSort" width="45%">
        <device-sorts @events="getData" :sortBtnShow="true"></device-sorts>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { export_json_to_excel } from "../../../introduce/Export2Excel";

import DeviceSort from "@/api/mangeDevice/DeviceSort";
import DeviceFactory from "@/api/mangeDevice/DeviceFactory";
import Device from "@/api/mangeDevice/DeviceDevice";
import DeviceDetail from "@/api/mangeDevice/DeviceDetail";

import DeviceExcel from "@/api/mangeDevice/DeviceExcel";

import DeviceSorts from "./DeviceSort.vue";
export default {
  components: {
    DeviceSorts,
  },
  props: {
    btnShow: {
        type: Boolean,
        default:false 
    }
  },
  data() {
    return {
      editHiden: false,
      fileDialogVisible: false,
      //文件
      file: "",
      filelist: [],
      errmesg: [],
      nullFactory: [],
      nullDevice: [],
      nullSort: [],
      factorybtn: {
        disable: false,
        message: "导入厂家",
      },
      devicebtn: {
        disable: false,
        message: "导入设备",
      },
      modelbtn: {
        disable: false,
        message: "导入产品",
      },
      sortbtn: {
        disable: false,
        message: "导入分类",
      },

      pages: {
        total: 0,
        limit: 10,
        current: 1,
      },
      pagess: {
        total: 0,
        limit: 10,
        current: 1,
      },
      detailForm: {
        factoryId: "",
        deviceId: "",
        deviceSort: "",
        deviceSortName: "",
        deviceModel: "",
        unit: "",
        warn: 0,
        remark: "",
      },
      detailFormRule: {
        factoryId: [{ required: true, message: "请选择厂家", trigger: "blur" }],
        deviceId: [{ required: true, message: "请选择设备", trigger: "blur" }],
        deviceSortName: [{ required: true, message: "请选择分类" }],
        deviceModel: [
          { required: true, message: "请输入设备型号", trigger: "blur" },
        ],
        unit: [{ required: true, message: "请输入计量单位", trigger: "blur" }],
        warn: [{ required: true, message: "请输入报警数量", trigger: "blur" }],
      },

      formDevice: {
        id: "",
        idx: "",
        deviceAlias: "",
        deviceName: "",
        factoryId: "",
      },
      formDeviceRule: {
        deviceName: [
          { required: true, message: "请输入设备名", trigger: "blur" },
        ],
        deviceAlias: [
          { required: true, message: "请输入设备名", trigger: "blur" },
        ],
      },

      dialogVisible: false, // 产品录入
      detailTitle: "产品录入",
      dialogVisible1: false, // 添加厂家
      dialogVisible2: false, // 添加设备

      dialogSort: false, // 添加设备

      allDeviceDetailList: [], // 型号集合

      allFactoryList: [], // 厂家集合
      allDeviceList: [], // 设备集合

      filterFactoryList: [], // 公司集合
      filterDeviceList: [],
      exportList: [], // 导出的表格

      // 添加新分类
      form: {
        id: "",
        factoryName: "",
        factoryAlias: "",
      },
      formRule: {
        factoryName: [
          { required: true, message: "请输入设备名", trigger: "blur" },
        ],
        factoryAlias: [
          { required: true, message: "请输入设备名", trigger: "blur" },
        ],
      },
      searchDetail: "", // 过滤文本
      select: "",
      inputplace: "请选择搜索类型",
    };
  },

  async created() {
    // await this.getModelList()
    await this.getFactoryList();
    await this.getMangeDeviceDetailList();
  },
  watch: {
    select: {
      handler(newValue, oldValue) {
        //newValue 新的值，oldValue变化前的值
        if (newValue == 1) {
          return (this.inputplace = "请输入厂家名称搜索");
        }
        if (newValue == 2) {
          return (this.inputplace = "请输入设备名称搜索");
        }
        if (newValue == 3) {
          return (this.inputplace = "请输入设备型号搜索");
        }
        if (newValue == 4) {
          return (this.inputplace = "请输入厂家别名搜索");
        }
        if (newValue == 5) {
          return (this.inputplace = "请输入设备别名搜索");
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 小程序
     */
    //  async getModelList() {
    //   let form ={
    //     limit: 10,
    //     current: 1,
    //   }
    //   const res = await DeviceDetail.getModelList(form);
    //   console.log(res);
    // },
    //导出excel
    async export2Excel() {
      // 获取所有设备信息导出
      const resp = await DeviceDetail.getAll();
      console.log(resp);
      if (resp) {
        this.exportList = resp.data.data.list;
        console.log(this.exportList);
        require.ensure([], () => {
          const tHeader = ["厂家名称", "设备名称", "设备型号", "分类名称", "计量单位"];
          const filterVal = ["factoryName", "deviceName", "deviceModel", "sortName", "unit"];
          const list = this.exportList; // 表格data

          const data = this.formatJson(filterVal, list);

          export_json_to_excel(tHeader, data, "设备信息"); // 对应下载文件的名字
        });
        this.loading = false;
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    handleExceed(e) {
      // 判断是否只能上传一个文件，超过提示报错
      this.$message.warning("只能上传一个文件哦");
    },
    async factoryChansge(file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message.warning("上传文件只能为excel文件，且为xlsx,xls格式");
        this.filelist = [];
        this.file = "";
        return false;
      }
      this.file = file.raw;
      await this.postfilefactory();
    },
    async postfilefactory() {
      if (this.file == "") {
        this.$message.warning("上传文件不能为空");
        return false;
      } else {
        // 文件形式的需要用 formData形式
        let formData = new FormData();
        formData.append("file", this.file);

        this.factorybtn.disable = true;
        this.factorybtn.message = "上传中，请等待";
        const res = await DeviceExcel.importExcelFactory(formData);

        if (res.data.data.map.status == 1) {
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
        if (res.data.data.map.repeat) {
          this.errmesg = res.data.data.map.repeat;
          console.log(this.errmesg);
        }
        this.filelist = [];
        this.factorybtn.disable = false;
        this.factorybtn.message = "导入厂家";
        await this.getFactoryList();
      }
    },

    async handleDevice(file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message.warning("上传文件只能为excel文件，且为xlsx,xls格式");
        this.filelist = [];
        this.file = "";
        return false;
      }
      this.file = file.raw;
      await this.postfiledevice();
    },
    async postfiledevice() {
      if (this.file == "") {
        this.$message.warning("上传文件不能为空");
        return false;
      } else {
        // 文件形式的需要用 formData形式
        let formData = new FormData();
        formData.append("file", this.file);

        this.devicebtn.disable = true;
        this.devicebtn.message = "上传中，请等待";
        const res = await DeviceExcel.importExcelDevice(formData);
        console.log(res);
        if (res.data.data.map.status == 1) {
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
        if (res.data.data.map.repeat) {
          this.errmesg = res.data.data.map.repeat;
          console.log(this.errmesg);
        }
        if (res.data.data.map.nullFactory) {
          this.nullFactory = res.data.data.map.nullFactory;
        }
        this.filelist = [];
        this.devicebtn.disable = false;
        this.devicebtn.message = "导入设备";
      }
    },
    async modelChansge(file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message.warning("上传文件只能为excel文件，且为xlsx,xls格式");
        this.filelist = [];
        this.file = "";
        return false;
      }
      this.file = file.raw;
      await this.postfilemodel();
    },
    async postfilemodel() {
      // 文件形式的需要用 formData形式
      let formData = new FormData();
      formData.append("file", this.file);

      this.modelbtn.disable = true;
      this.modelbtn.message = "上传中，请等待";
      const res = await DeviceExcel.importExcelModel(formData);
      console.log(res);

      if (res.data.data.map.status == 1) {
        this.$message.success("上传成功");
        await this.getMangeDeviceDetailList();
      } else {
        this.$message.warning("上传失败");
      }
      if (res.data.data.map.repeat) {
        this.errmesg = res.data.data.map.repeat;
        console.log(this.errmesg);
      }
      if (res.data.data.map.nullDevice) {
        this.nullDevice = res.data.data.map.nullDevice;
      }
      if (res.data.data.map.nullSort) {
        this.nullSort = res.data.data.map.nullSort;
      }

      this.filelist = [];
      this.modelbtn.disable = false;
      this.modelbtn.message = "导入产品";
    },
    // 导入分类
    async sortChansge(file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message.warning("上传文件只能为excel文件，且为xlsx,xls格式");
        this.filelist = [];
        this.file = "";
        return false;
      }
      this.file = file.raw;
      await this.postfilesort();
    },
    async postfilesort() {
      // 文件形式的需要用 formData形式
      let formData = new FormData();
      formData.append("file", this.file);

      this.sortbtn.disable = true;
      this.sortbtn.message = "上传中，请等待";
      const res = await DeviceExcel.importExcelSort(formData);
      console.log(res);
      if (res.data.data.map.status == 1) {
        this.$message.success("上传成功");
        // 调用获取分类
        console.log("分类");
        this.triggerBrotherMethods()
      } else {
        this.$message.warning("上传失败");
      }
      if (res.data.data.map.repeat) {
        this.errmesg = res.data.data.map.repeat;
        console.log(this.errmesg);
      }

      this.filelist = [];
      this.sortbtn.disable = false;
      this.sortbtn.message = "导入分类";
    },
    // 兄弟方法 分类刷新
    triggerBrotherMethods() {
      this.$emit( 'clickBorther')
    },
    handleDialogClose() {
      this.filelist = [];
      this.file = "";
      this.errmesg = [];
      this.nullFactory = [];
      this.fileDialogVisible = false;
    },

    openDialog() {
      this.detailTitle = "产品录入";
      this.dialogVisible = true;
    },
    checkDeviceDetail(row) {
      console.log(row);
      this.$emit("getDeviceDetail", row); // row为需要传递的参数对象
    },
    editDeviceDetail(row) {
      this.editHiden = true;
      this.detailTitle = "产品编辑";
      this.dialogVisible = true;
      let aaa = JSON.parse(JSON.stringify(row));
      console.log(aaa);
      this.detailForm = aaa;
    },
    // 删除产品
    async delDeviceDetail(row) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => console.log(err));
      if (confirmRes !== "confirm") return this.$message.info("已经取消删除");

      const res = await DeviceDetail.delDetail(row);
      console.log(res);
      //提示信息
      if (res.data.message == "删除成功") {
        this.$message.success(res.data.message);
      }
      await this.getMangeDeviceDetailList();
    },
    async addDeviceDetail(detailForm) {
      const valid = await this.$refs[detailForm].validate();
      if (valid) {
        console.log("111");
        if (this.editHiden) {
          console.log("编辑");
          const res = await DeviceDetail.editDetail(this.detailForm);
          if (res.data.message == "更新成功") {
            this.$message.success(res.data.message);
          } else {
            this.$message.warning(res.data.message);
          }
        } else {
          console.log("添加");
          const res = await DeviceDetail.addMangeDeviceDetail(this.detailForm);

          if (res.data.message == "添加成功") {
            this.$message.success(res.data.message);
          } else {
            this.$message.warning(res.data.message);
          }
        }
        this.cancalDeviceDetal(detailForm);
        await this.getMangeDeviceDetailList();
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    getData(val) {
      this.detailForm.deviceSort = val.id;
      this.detailForm.deviceSortName = val.sortName;
      this.dialogSort = false;
    },
    factoryFilter(val) {
      return (this.filterFactoryList = this.allFactoryList.filter(
        (data) =>
          data.factoryAlias.includes(val) || data.factoryName.includes(val)
      ));
    },
    deviceFilter(val) {
      return (this.filterDeviceList = this.allDeviceList.filter(
        (data) =>
          data.deviceAlias.includes(val) || data.deviceName.includes(val)
      ));
    },
    /**
     * 厂品展示
     */
    async getMangeDeviceDetailList() {
      const res = await DeviceDetail.getMangeDeviceDetailList(this.pages);
      this.allDeviceDetailList = res.data.data.list;
      this.pages.total = res.data.data.total;
    },
    async selectBy() {
      if (this.searchDetail) {
        if (this.select == 1) {
          let form = { ...{ factoryName: this.searchDetail }, ...this.pages };
          return await this.byFactory(form);
        } else if (this.select == 2) {
          let form = { ...{ deviceName: this.searchDetail }, ...this.pages };
          return await this.byDeviceName(form);
        } else if (this.select == 3) {
          let form = { ...{ deviceModel: this.searchDetail }, ...this.pages };
          return await this.byModel(form);
        } else if (this.select == 4) {
          let form = { ...{ factoryAlias: this.searchDetail }, ...this.pages };
          return await this.byfactoryAlias(form);
        } else if (this.select == 5) {
          let form = { ...{ deviceAlias: this.searchDetail }, ...this.pages };
          return await this.bydeviceAlias(form);
        } else {
          return await this.getMangeDeviceDetailList();
        }
      }
      return await this.getMangeDeviceDetailList();
    },
    async byFactory(e) {
      const res = await DeviceDetail.getDetailListByFactory(e);
      this.allDeviceDetailList = res.data.data.list;
      this.pages.total = res.data.data.total;
      if (res.data.message == "查询成功") {
        this.$message.success(res.data.message);
      } else {
        this.$message.warning(res.data.message);
      }
    },
    async byfactoryAlias(e) {
      const res = await DeviceDetail.getDetailListByFactoryAlias(e);
      this.allDeviceDetailList = res.data.data.list;
      this.pages.total = res.data.data.total;
      if (res.data.message == "查询成功") {
        this.$message.success(res.data.message);
      } else {
        this.$message.warning(res.data.message);
      }
    },
    async bydeviceAlias(e) {
      const res = await DeviceDetail.getDetailListByDeviceAlias(e);
      this.allDeviceDetailList = res.data.data.list;
      this.pages.total = res.data.data.total;
      if (res.data.message == "查询成功") {
        this.$message.success(res.data.message);
      } else {
        this.$message.warning(res.data.message);
      }
    },
    async byDeviceName(e) {
      const res = await DeviceDetail.getDetailListByDevice(e);
      this.allDeviceDetailList = res.data.data.list;
      this.pages.total = res.data.data.total;
      if (res.data.message == "查询成功") {
        this.$message.success(res.data.message);
      } else {
        this.$message.warning(res.data.message);
      }
    },
    async byModel(e) {
      const res = await DeviceDetail.getDetailListByModel(e);
      this.allDeviceDetailList = res.data.data.list;
      this.pages.total = res.data.data.total;
      if (res.data.message == "查询成功") {
        this.$message.success(res.data.message);
      } else {
        this.$message.warning(res.data.message);
      }
    },
    // 厂家信息 下拉框/表格
    async getFactoryList() {
      const res = await DeviceFactory.getFactoryList(this.pagess);
      this.allFactoryList = res.data.data.list;
      this.filterFactoryList = JSON.parse(JSON.stringify(this.allFactoryList));
    },
    async getDevice(row) {
      // 下拉框  公司下的设备
      this.detailForm.deviceId = "";
      let form = {
        factoryId: row,
      };
      const res = await Device.getDevice(form);
      this.allDeviceList = res.data.data.list;
      this.filterDeviceList = res.data.data.list;
    },
    // 添加设备
    addDevice() {
      this.formDevice.factoryId = this.detailForm.factoryId;
      this.dialogVisible2 = true;
    },
    // 添加某个厂家下的新的设备
    async addNewDevice(formDevice) {
      const valid = await this.$refs[formDevice].validate();
      console.log(valid);
      if (valid) {
        console.log(this.formDevice);
        const res = await Device.addDevice(this.formDevice);
        //提示信息
        this.$message({
          type: res.data.success ? "success" : "warning",
          message: res.data.message,
        });
        this.dialogVisible2 = false;
        await this.getDevice(this.detailForm.factoryId);
        this.$refs[formDevice].resetFields();
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    // 分页
    async currentPage(value) {
      await this.getMangeDeviceDetailList();
    },

    // 添加新的厂家
    async addNewDeviceFactory(form) {
      const valid = await this.$refs[form].validate();
      if (valid) {
        const res = await DeviceFactory.addFactory(this.form);
        //提示信息
        this.$message({
          type: res.data.success ? "success" : "warning",
          message: res.data.message,
        });
        this.dialogVisible1 = false;
        await this.getFactoryList();

        this.$refs[form].resetFields();
      } else {
        console.log("error submit!!");
        return false;
      }
    },

    // 删除分类
    async delDeviceSort(id) {
      this.form.id = id;
      const confirmRes = await this.$confirm(
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => console.log(err));
      if (confirmRes !== "confirm") return this.$message.info("已经取消删除");

      const res = await DeviceSort.delSortById(this.form);
      //提示信息
      this.$message({
        type: res.data.success ? "success" : "warning",
        message: res.data.message,
      });
      //回到页面并刷新
      this.getSortTree();
    },
    cancalDeviceDetal(detailForm) {
      this.dialogVisible = false;
      this.$refs[detailForm].resetFields();
    },
    dialogclosed() {
      console.log("关闭了");
      this.editHiden = false;
      this.detailForm = {};
    },
    async restList() {
      this.searchDetail = "";
      await this.getMangeDeviceDetailList();
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 10px 5px;
}
// input type=number时 去掉后面上下按钮
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.add,
.del {
  font-size: 16px;
  padding: 2px 5px;
}

/deep/.el-dialog__title {
  font-size: 24px;
  font-weight: 600;
  color: #555;
}

.company-info {
  width: 100%;
  box-sizing: border-box;
  //   border: 1px solid red;
  display: flex;

  // height: 100px;
  .left {
    width: 30%;
    height: 282px;

    // border: 1px solid #aaa;
    .img {
      margin-left: 20%;
      box-sizing: border-box;
      width: 70%;
      height: 282px;
      //   border: 1px solid #aaa;
    }
  }

  .right {
    width: 70%;
    // border: 1px solid rgb(247, 110, 110);
  }
}

.inpt {
  width: 40%;
  //   opacity: 0.2;
  display: none;
}

.upload {
  margin: 10px;
  cursor: pointer;
}

.icon:hover {
  color: aquamarine;
}
</style>
